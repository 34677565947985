import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: async (to, from, next) => {
      try {
        const response = await fetch('https://skpb.wmt35.idv.tw/existAdmin');
        const data = await response.text();
        if (data === '1') {
          next('Login');
        } else {
          next('AddAdmin');
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        next('NotFound');
      }
    },
  },
  {
    path: '/addAdmin',
    name: 'AddAdmin',
    component: () => import('../components/AddAdmin.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/Login.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../components/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
